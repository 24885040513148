$color-1: #C39108;
$color-2: #000000;
$color-3: #000000;
$color-4: #D1D1D1;
$color-5: #000000;
$color-6: #C39108;
$color-7: #C39108;
$color-8: #D1D1D1;
$color-9: transparent;
$color-10: #FFFFFF;
$color-11: #F5F5F5;
$color-12: #E9E9E9;
$color-13: #D1D1D1;
$color-14: #979797;
$color-15: #5D5D5D;
$color-16: #3A3A3A;
$color-17: #000000;
$color-18: #FFFFFF;

$color-100: #F9F9F9;
