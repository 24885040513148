$form-field-optional: $color-14;

$form-fields-radius: 0px;

$form-multiselect-tag: $color-10;
$form-multiselect-tag-background: $color-15;
$form-multiselect-tag-hover: $color-10;
$form-multiselect-tag-background-hover: $color-15;
$form-multiselect-tag-background-active: $color-16;
$form-multiselect-tag-remove: $color-10;
$form-multiselect-tags-clear-background: $color-15;
$form-multiselect-tags-clear: $color-10;
$form-multiselect-tags-clear-hover: $color-10;
$form-multiselect-tags-clear-active: $color-10;
$form-multiselect-tags-clear-background-hover: $color-16;
$form-multiselect-tags-clear-background-active: $color-17;
$form-multiselect-tags-container-placeholder: $form-field-placeholder;
$form-multiselect-tags-container-background: $form-field-background;
$form-multiselect-tags-container-border: $form-field-border;
$form-multiselect-tags-container-border-focus: $form-field-border;

$form-multiselect-options: $color-16;
$form-multiselect-options-border: $color-4;
$form-multiselect-options-hover: $color-1;
$form-multiselect-options-active: $color-1;
$form-multiselect-options-background: $color-10;
$form-multiselect-options-background-hover: $color-10;
$form-multiselect-options-background-active: $color-10;

$pagination-navigation: $color-16;
$pagination-navigation-page-field: $color-16;
$pagination-navigation-page-field-background: $color-10;
$pagination-navigation-page-field-border: $color-13;

$confirmation-header: $color-10;
$confirmation-header-background: $color-15;
$confirmation-body: $color-16;
